export var LogType;
(function (LogType) {
    LogType[LogType["LOGIN"] = 1] = "LOGIN";
    LogType[LogType["CLICK"] = 2] = "CLICK";
    LogType[LogType["USER_ACTIVITY"] = 3] = "USER_ACTIVITY";
})(LogType || (LogType = {}));
export var LogItemType;
(function (LogItemType) {
    LogItemType[LogItemType["PRODUCT"] = 1] = "PRODUCT";
    LogItemType[LogItemType["EVENT"] = 2] = "EVENT";
    LogItemType[LogItemType["RESOURCE"] = 3] = "RESOURCE";
    LogItemType[LogItemType["CHECKOUT"] = 4] = "CHECKOUT";
    LogItemType[LogItemType["DIRECTV_DEMO"] = 5] = "DIRECTV_DEMO";
    LogItemType[LogItemType["DEALER_DATA_CHANGING"] = 6] = "DEALER_DATA_CHANGING";
    LogItemType[LogItemType["DEALER_DELETING"] = 7] = "DEALER_DELETING";
})(LogItemType || (LogItemType = {}));
