import { UserHelper, ChannelHelper, UserActivityLogKey } from '@monorepo/infra'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTree = (tree?: any[], principals?: string[]) => {
  if (!Array.isArray(tree) || !Array.isArray(principals)) {
    return undefined
  }

  const principalsIdx = 0,
    adminsIdx = 1,
    membersIdx = 2

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const masterDealers: { [key: string]: any } = {}

  for (let i = 0, l = tree.length; i < l; ++i) {
    if (!masterDealers[tree[i].masterDealerId]) {
      masterDealers[tree[i].masterDealerId] = {
        id: tree[i].masterDealerId,
        name: tree[i].name,
        children: [
          {
            id: `principals-${tree[i].masterDealerId}`,
            name: 'Principals',
            children: [],
          },
          {
            id: `admins-${tree[i].masterDealerId}`,
            name: 'Admins',
            children: [],
          },
          {
            id: `members-${tree[i].masterDealerId}`,
            name: 'Members',
            children: [],
          },
        ],
      }
    }

    const principalsNode =
      masterDealers[tree[i].masterDealerId].children[principalsIdx]
    const adminsNode = masterDealers[tree[i].masterDealerId].children[adminsIdx]
    const membersNode =
      masterDealers[tree[i].masterDealerId].children[membersIdx]

    const user = tree[i].dealers?.users

    if (!user?.email) {
      continue
    }

    let node
    if (
      Array.isArray(tree[i].principals) &&
      tree[i].principals.indexOf(user.email.toLowerCase()) !== -1
    ) {
      node = principalsNode
    } else if (principals.indexOf(user.email.toUpperCase()) !== -1) {
      node = principalsNode
    } else if (UserHelper.isAdmin(user.groups)) {
      node = adminsNode
    } else {
      node = membersNode
    }
    node.children.push(getTreeUser(user))
  }

  const keys = Object.keys(masterDealers)
  const keysLength = keys.length

  let result

  if (keysLength > 1) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result = { id: 'root', children: [] as any[] }
    for (let i = 0; i < keysLength; ++i) {
      result.children.push(masterDealers[keys[i]])
    }
  } else {
    result = masterDealers[keys[0]]
  }

  return result
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTreeUser = (user: any) => ({
  id: user._id,
  name: `${user.firstName?.trim()}${
    !!user.firstName?.trim() && !!user.lastName?.trim() ? ' ' : ''
  }${user.lastName?.trim()}`,
  email: user.email.toUpperCase(),
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTreeDataToExport = (data: any[], principals?: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result = [] as any[]

  if (!Array.isArray(data)) {
    return result
  }

  for (let i = 0, l = data.length; i < l; ++i) {
    const masterDealer = data[i]

    if (!masterDealer?.dealers?.users?.enabled) {
      continue
    }

    const masterDealerId = masterDealer.masterDealerId

    for (let ii = 0, ll = masterDealer.channels.length; ii < ll; ++ii) {
      const channel = masterDealer.channels[ii].toUpperCase()
      const userAssociatedMasterDealers = Array.isArray(
        masterDealer.dealers.associatedMasterDealers
      )
        ? masterDealer.dealers.associatedMasterDealers.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (item: any) => item.masterDealerId === masterDealerId
          )
        : []

      if (userAssociatedMasterDealers.length) {
        const userChannels = ChannelHelper.getAssociatedMasterDealerChannels(
          userAssociatedMasterDealers
        ).map((item) => item.toUpperCase())
        if (userChannels.length && userChannels.indexOf(channel) === -1) {
          continue
        }
      }

      result.push({
        Channel: channel,
        'Master Dealer ID': masterDealerId,
        'Master Dealer Name': masterDealer.name,
        ...getUserDataToExport(
          masterDealer.dealers.users,
          principals,
          masterDealer.principals
        ),
      })
    }
  }

  return result
}

const getUserDataToExport = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  principals: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  masterDealerPrincipals: any
) => {
  return {
    'User Role': getUserRole(user, principals, masterDealerPrincipals),
    'User First Name': user.firstName,
    'User Last Name': user.lastName,
    'User Email': user.email,
  }
}

const getUserRole = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  principals: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  masterDealerPrincipals: any
) => {
  return Array.isArray(masterDealerPrincipals) &&
    masterDealerPrincipals.indexOf(user.email.toLowerCase()) !== -1
    ? 'Principal'
    : Array.isArray(principals) &&
      principals.indexOf(user.email.toUpperCase()) !== -1
    ? 'Principal'
    : UserHelper.isAdmin(user.groups)
    ? 'Admin'
    : 'Member'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserActivityTree = (data: any[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userActivities = []
  for (const log in data) {
    userActivities.push(setMessageByKey(data[log]))
  }
  return userActivities
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setMessageByKey = (log: any) => {
  switch (log.key) {
    case UserActivityLogKey.TEAM_MEMBER_DELETED:
      return `${log.date} Team Member \"${log.latestChange.currentValue}\" was deleted by ${log.user}`
    case UserActivityLogKey.UNIQUE_DEALER_ID:
      return `${log.date} Unique dealer ID was changed ${log.latestChange.previusValue} to ${log.latestChange.currentValue} by ${log.user}`
    case UserActivityLogKey.BUSINESS_NAME:
      return `${log.date} Business name was changed ${log.latestChange.previusValue} to ${log.latestChange.currentValue} by ${log.user}`
    case UserActivityLogKey.TEAM_MEMBER_ADDED:
      return `${log.date} Team Member \"${log.latestChange.currentValue}\" was added by ${log.user}`
    case UserActivityLogKey.COOP_ALLOWANCES:
      return `${log.date} Co-Op Allownces were changed ${log.latestChange.previusValue} to ${log.latestChange.currentValue} by ${log.user}`
    case UserActivityLogKey.GROUPS:
      return `${log.date} Groups were changed ${log.latestChange.previusValue} to ${log.latestChange.currentValue} by ${log.user}`
    case UserActivityLogKey.CHANNELS:
      return `${log.date} Channels were changed ${log.latestChange.previusValue} to ${log.latestChange.currentValue} by ${log.user}`
    case UserActivityLogKey.ASSOCIATED_MASTER_DEALERS:
      return `${log.date} Associated Master Dealers were changed ${log.latestChange.previusValue} to ${log.latestChange.currentValue} by ${log.user}`
    case UserActivityLogKey.DEALER_TYPES:
      return `${log.date} Dealer types were changed ${log.latestChange.previusValue} to ${log.latestChange.currentValue} by ${log.user}`
    case UserActivityLogKey.HAS_COOP:
      return `${log.date} Co-Op ${
        Boolean(log.latestChange.currentValue) ? 'Enabled' : 'Disabled'
      } by ${log.user}`
    case UserActivityLogKey.GROUP:
      return `${log.date} ${dealerType(
        log.latestChange.previusValue
      )} to ${dealerType(log.latestChange.currentValue)} by ${log.user}`
    case UserActivityLogKey.PREFERRED_DEALER:
      return `${log.date} ${
        log.latestChange.currentValue ? 'Enabled' : 'Disabled'
      } Preferred by ${log.user}`
    case 'top_event_dealer':
      return `${log.date} ${
        log.latestChange.currentValue ? 'Enabled' : 'Disabled'
      } Top Events by ${log.user}`
    case 'authorized_dealer':
      return `${log.date} ${
        log.latestChange.currentValue ? 'Enabled' : 'Disabled'
      } Authorized by ${log.user}`
    case 'heb_dealer':
      return `${log.date} ${
        log.latestChange.currentValue ? 'Enabled' : 'Disabled'
      } HEB by ${log.user}`
    case UserActivityLogKey.ENABLE:
    case UserActivityLogKey.ENABLED:
      return `${log.date} ${
        log.latestChange.currentValue ? 'Active' : 'Inactive'
      } by ${log.user}`
    case 'lsp':
    case 'LSP':
    case 'mdu':
    case 'MDU':
    case 'd2d':
    case 'D2D':
    case 'em':
    case 'EM':
    case 'nr':
    case 'NR':
    case 'ISP':
    case 'isp':
      return `${log.date} ${
        log.latestChange.currentValue ? 'Enabled' : 'Disabled'
      } ${log.key.toUpperCase()} by ${log.user}`
    default:
      return
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dealerType = (dealerGroup: string) => {
  switch (dealerGroup) {
    case '/dealerAdmin':
    case 'dealerAdmin':
      return 'Admin'
    case '/dealer':
    case 'dealer':
      return 'Member'
    default:
      return dealerGroup
  }
}
