"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportType = void 0;
var ReportType;
(function (ReportType) {
    ReportType["ACTIVITY_BY_DEALER"] = "activityByDealer";
    ReportType["ACTIVITY_BY_PRODUCT"] = "activityByProduct";
    ReportType["PRODUCTS_ORDERED"] = "productsOrdered";
    ReportType["PRODUCT_CATALOG"] = "productCatalog";
    ReportType["SPEND_BY_MEDIA"] = "spendByMedia";
    ReportType["EXECUTIVE_SUMMARY"] = "executiveSummary";
    ReportType["PRODUCT_DOWNLOAD_BREAKDOWN"] = "productDownloadBreakdown";
    ReportType["ACTIVE_USERS_BY_CHANNEL"] = "activeUsersByChannel";
    ReportType["FINANCIAL_REPORTING_BY_VENDOR"] = "financialReportingByVendor";
    ReportType["RESOURCE_CENTER_UTILIZATION"] = "resourceCenterUtilization";
    ReportType["DEALER_ENGAGEMENT"] = "Dealer Engagement";
    ReportType["SUMMARY_OF_OPEN_CLOSED_TICKETS"] = "Summary of open/closed tickets";
    ReportType["SUMMARY_OF_TICKETS_BY_CHANNEL"] = "Summary of tickets by channel";
    ReportType["SUPPORT_TICKET_LEVEL_DETAILS"] = "Support ticket level details";
    ReportType["DELETED_USERS"] = "Deleted users";
})(ReportType = exports.ReportType || (exports.ReportType = {}));
