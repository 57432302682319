import styled from 'styled-components'
import { Button as BaseButton } from '@monorepo/components'
import { StylesConfig } from 'react-select'
import { Form } from 'formik'
import { IReactSelectOption } from '@monorepo/interfaces'
import { colors } from '@monorepo/theme'

export const ModalButton = styled(BaseButton)`
  width: 100px;
  justify-content: center;
`

type IsMulti = true

export const SelectStyle: StylesConfig<IReactSelectOption> = {
  container: (base) => ({
    ...base,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
    minHeight: 75,
    paddingLeft: 20,
    paddingRight: 20,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: '#21528C',
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'PFDINText',
    fontSize: 16,
    letterSpacing: -0.2,
    fontWeight: 'normal',
    color: colors.mediumGrey,
  }),
}

export const MultiSelectStyle: StylesConfig<IReactSelectOption, IsMulti> = {
  container: SelectStyle.container,
  control: SelectStyle.control,
  placeholder: SelectStyle.placeholder,
  multiValueLabel: (base) => ({
    ...base,
    color: colors.white,
    padding: 0,
    marginRight: 10,
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    padding: 0,
    height: 20,
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '.2s ease-in-out background-color',
    backgroundColor: state.isFocused ? 'black' : 'transparent',
    '&:hover': {
      backgroundColor: 'black',
      color: colors.white,
      cursor: 'pointer',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#21528C',
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 5,
    borderRadius: 18,
    color: colors.white,
    padding: '0 10px',
  }),
  menu: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  menuList: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    display: 'none',
  }),
}

export const DialogActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const DialogForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ErrorMessage = styled.p`
  color: ${colors.red};
  font-family: PFDINText;
  font-size: 14px;
`

export const DeleteCoopAllowanceButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`

export const CheckboxCoopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
`
