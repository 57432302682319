import styled from 'styled-components'
import { Button } from '@monorepo/components'
import BaseSeparator from '../../atoms/Separator'
import { Search as BaseSearch, SearchProps } from '@monorepo/components'
import { colors } from '@monorepo/theme'
import {
  AlertDialog as ReachAlertDialog,
  AlertDialogOverlay as ReachAlertDialogOverlay,
} from '@reach/alert-dialog'
import Dialog from '@reach/dialog'

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SearchFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Search = styled(BaseSearch).attrs({
  backgroundColor: colors.white,
  width: 325,
  height: 36,
})<SearchProps>``

export const PageActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 54px;
  width: 100%;
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 213px;
  height: 38px;
`

export const CreateUserButton = styled(Button)`
  padding: 8px 16px;
  width: 131px;
  height: 38px;
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`

export const Separator = styled(BaseSeparator)`
  && {
    width: unset;
    margin: 0 -32px;
    margin-top: -21px;
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const AlertDialogOverlay = styled(ReachAlertDialogOverlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 20, 0.5);
`

export const AlertDialog = styled(ReachAlertDialog)`
  position: fixed;
  height: 80vh;
  width: 60vw;
  max-width: 80vw;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.white};
  margin: auto;
  overflow-y: auto;
`

export const CreateUserDialog = styled(Dialog)`
  background: #f5f5f5;
`

export const CreateUserDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
