"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserActivityLogKey = void 0;
var UserActivityLogKey;
(function (UserActivityLogKey) {
    UserActivityLogKey["TEAM_MEMBER_ADDED"] = "teammemberadded";
    UserActivityLogKey["DEALER_ID"] = "dealer._id";
    UserActivityLogKey["TEAM_MEMBER_DELETED"] = "teammemberdeleted";
    UserActivityLogKey["UNIQUE_DEALER_ID"] = "uniqueDealerId";
    UserActivityLogKey["BUSINESS_NAME"] = "businessName";
    UserActivityLogKey["COOP_ALLOWANCES"] = "coopAllowances";
    UserActivityLogKey["GROUP"] = "group";
    UserActivityLogKey["GROUPS"] = "groups";
    UserActivityLogKey["CHANNELS"] = "channels";
    UserActivityLogKey["ASSOCIATED_MASTER_DEALERS"] = "associatedMasterDealers";
    UserActivityLogKey["DEALER_TYPES"] = "dealerTypes";
    UserActivityLogKey["HAS_COOP"] = "hasCoop";
    UserActivityLogKey["PREFERRED_DEALER"] = "preferred_dealer";
    UserActivityLogKey["ENABLE"] = "enable";
    UserActivityLogKey["ENABLED"] = "enabled";
})(UserActivityLogKey = exports.UserActivityLogKey || (exports.UserActivityLogKey = {}));
