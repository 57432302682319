import { useEffect, useState } from 'react'
import * as S from './styles'

import {
  Event,
  EventPropsData,
  EventStatus,
  IReactSelectOption,
} from '@monorepo/interfaces'

import { Spinner, Icon } from '@monorepo/components'
import { TableBody } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AxiosResponse } from 'axios'
import {
  EventHelper,
  formatAxiosError,
  formatCurrency,
  http,
  isObject,
} from '@monorepo/infra'

export interface SectionEventsListProps extends EventPropsData {
  loading: boolean
}

const inlineEditingEventSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  categories: Yup.array().of(Yup.string()).optional(),
  price: Yup.string().required('Price is required'),
  activationDate: Yup.date().nullable().optional(),
  expirationDate: Yup.date().nullable().optional(),
  tags: Yup.array().of(Yup.string()).optional(),
  status: Yup.number().required('Status is required'),
  sku: Yup.string()
    .required('SKU is required')
    .test(
      'checkDupSKU',
      'An item with this SKU already exists',
      function (value, context) {
        return new Promise((resolve, reject) => {
          http
            .get<AxiosResponse>({
              url: `/event?sku=${value}`,
            })
            .then((response) => {
              if (
                Array.isArray(response.data.data) &&
                response.data.data.filter(
                  (item: Event) =>
                    item._id != context.parent._id && item.sku == value
                ).length
              ) {
                resolve(false)
              }

              resolve(true)
            })
            .catch(() => {
              resolve(false)
            })
        })
      }
    ),
})

const inlineEditingEventInitialValues = {
  title: '',
  categories: [] as string[],
  price: '',
  activationDate: null as Date | null | undefined,
  expirationDate: null as Date | null | undefined,
  tags: [] as string[],
  status: EventStatus.DRAFT,
  sku: '',
  _id: undefined as string | undefined,
}

const SectionEventsList: React.FC<SectionEventsListProps> = ({
  events,
  loading = true,
}) => {
  const { t } = useTranslation('admin')
  const history = useHistory()
  const [inlineEditingItem, setInlineEditingItem] = useState<Event | undefined>(
    undefined
  )
  const [tagsOptions, setTagsOptions] = useState<IReactSelectOption[]>([])
  const [saving, setSaving] = useState(false)
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
  const [savingError, setSavingError] = useState<string | undefined>(undefined)

  const { errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    validationSchema: inlineEditingEventSchema,
    initialValues: inlineEditingEventInitialValues,
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        console.log(values)
      })
    },
  })

  const sx = {
    '& .MuiInputBase-root': {
      'font-family': 'PFDINText',
      'font-size': '14px',
      'font-style': 'normal',
      'font-weight': '400',
      'line-height': '16px',
    },
  }

  useEffect(() => {
    http
      .get({ url: '/event/tags' })
      .then((response) => {
        if (Array.isArray(response?.data)) {
          setTagsOptions(
            response?.data.map((item) => ({ value: item, label: item }))
          )
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const handleEditEvent = (index: number) => {
    const id = events[index]._id
    history.push(`/events/edit/${id}`)
  }

  const handleInlineEditClick = (item?: Event) => {
    if (!item) {
      return
    }

    setInlineEditingItem(item)
    formik.setValues({
      title: item.title,
      categories: item.categories,
      price: item.price,
      activationDate: item.activationDate,
      expirationDate: item.expirationDate,
      tags: item.tags,
      status: item.status,
      sku: item.sku,
      _id: item._id,
    })
  }

  const handleInlineEditSaveClick = async () => {
    if (!formik.isValid) {
      return
    }

    const changedItem = {
      ..._.cloneDeep(inlineEditingItem),
      ...formik.values,
    } as Event

    if (EventHelper.isEventEqual(inlineEditingItem, changedItem)) {
      setInlineEditingItem(undefined)
      return
    }

    const savedItem = (await saveItem(changedItem)) as Event

    if (Array.isArray(events) && isObject(savedItem)) {
      const idx = events.findIndex((item) => item._id === savedItem._id)
      if (idx !== -1) {
        events[idx] = savedItem
      }
    }

    setInlineEditingItem(undefined)
  }

  const saveItem = async (item: Event) => {
    setSaving(true)
    setSavingError(undefined)

    let savedItem: Event | undefined = undefined

    try {
      const response = await http.patch({
        url: `/event/${item._id}`,
        data: item,
      })

      if (response.status === 200 && isObject(response.data)) {
        savedItem = response.data as Event
      }
    } catch (error) {
      setSavingError(formatAxiosError(error))
      setIsAlertDialogOpen(true)
    } finally {
      setSaving(false)
    }

    return savedItem
  }

  const handleInlineEditCancelClick = () => {
    setInlineEditingItem(undefined)
  }

  return (
    <S.Container>
      <S.TableContainer>
        <S.Table aria-label="Events Table">
          <S.TableHead>
            <S.TableRow>
              <S.TableHeadCell>
                {t('section_events_list.table_title_1')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_2')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_3')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_4')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_5')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_6')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_7')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_8')}
              </S.TableHeadCell>
              <S.TableHeadCell></S.TableHeadCell>
            </S.TableRow>
          </S.TableHead>
          {
            <TableBody>
              {loading || saving ? (
                <S.TbodyRowLoading>
                  <S.TBodyCellLoading scope="row">
                    <Spinner spinnerSize={8} />
                  </S.TBodyCellLoading>
                </S.TbodyRowLoading>
              ) : (
                events?.map((event: Event, index: number) =>
                  inlineEditingItem?._id === event._id ? (
                    <S.TbodyRow key={index}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <S.TBodyCell scope="row">
                          <TextField
                            id="title"
                            name="title"
                            label="Title"
                            required
                            value={formik.values.title}
                            onChange={({ target: { value } }) =>
                              formik.setFieldValue('title', value)
                            }
                            error={!!errors.title && touched.title}
                            helperText={errors.title}
                            sx={sx}
                          />
                        </S.TBodyCell>
                        <S.TBodyCell scope="row">
                          <TextField
                            id="sku"
                            name="sku"
                            label="SKU"
                            required
                            value={formik.values.sku}
                            onChange={({ target: { value } }) =>
                              formik.setFieldValue('sku', value)
                            }
                            error={!!errors.sku && touched.sku}
                            helperText={errors.sku}
                            sx={sx}
                          />
                        </S.TBodyCell>
                        <S.TBodyCell scope="row">
                          <CreatableSelect
                            id="categories"
                            name="categories"
                            placeholder="DIRECTV, AT&T"
                            isMulti
                            options={[]}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator: () => null,
                            }}
                            value={formik.values.categories.map((category) => ({
                              value: category,
                              label: category,
                            }))}
                            onChange={(newValue) =>
                              formik.setFieldValue(
                                'categories',
                                newValue.map(({ value }) => value)
                              )
                            }
                            styles={S.MultiSelectStyle}
                          />
                        </S.TBodyCell>
                        <S.TableCell scope="row">
                          <TextField
                            id="price"
                            name="price"
                            label="Price"
                            required
                            value={formik.values.price}
                            onChange={({ target: { value } }) =>
                              formik.setFieldValue(
                                'price',
                                formatCurrency(value)
                              )
                            }
                            error={!!errors.price && touched.price}
                            helperText={errors.price}
                            sx={sx}
                          />
                        </S.TableCell>
                        <S.TBodyCell scope="row">
                          <S.KeyboardDateTimePicker
                            id="activationDate"
                            name="activationDate"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Select a date & time"
                            format="MM/dd/yy hh:mm a"
                            value={formik.values.activationDate ?? null}
                            onChange={(date) =>
                              formik.setFieldValue('activationDate', date)
                            }
                            ampm
                          />
                        </S.TBodyCell>
                        <S.TBodyCell scope="row">
                          <S.KeyboardDateTimePicker
                            id="expirationDate"
                            name="expirationDate"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Select a date & time"
                            format="MM/dd/yy hh:mm a"
                            value={formik.values.expirationDate ?? null}
                            onChange={(date) =>
                              formik.setFieldValue('expirationDate', date)
                            }
                            ampm
                          />
                        </S.TBodyCell>
                        <S.TBodyCell scope="row">
                          <CreatableSelect
                            id="tags"
                            name="tags"
                            placeholder="Bestseller, Featured"
                            isMulti
                            options={tagsOptions}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator: () => null,
                            }}
                            value={formik.values.tags.map((tag) => ({
                              value: tag,
                              label: tag,
                            }))}
                            onChange={(newValue) =>
                              formik.setFieldValue(
                                'tags',
                                newValue.map(({ value }) => value)
                              )
                            }
                            styles={S.MultiSelectStyle}
                          />
                        </S.TBodyCell>
                        <S.TBodyCell scope="row">
                          <TextField
                            id="status"
                            name="status"
                            select
                            label="Status"
                            value={formik.values.status.toString()}
                            defaultValue={EventStatus.DRAFT.toString()}
                            onChange={({ target: { value } }) =>
                              formik.setFieldValue('status', parseInt(value))
                            }
                            sx={sx}
                          >
                            {EventHelper.eventStatusOptions.map(
                              (option: IReactSelectOption) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </S.TBodyCell>
                        <S.TBodyCell scope="row">
                          <IconButton
                            aria-label="save"
                            title="Save"
                            onClick={handleInlineEditSaveClick}
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton
                            aria-label="cancel"
                            title="Cancel"
                            onClick={handleInlineEditCancelClick}
                          >
                            <CancelIcon />
                          </IconButton>
                        </S.TBodyCell>
                      </MuiPickersUtilsProvider>
                    </S.TbodyRow>
                  ) : (
                    <S.TbodyRow
                      key={index}
                      onClick={() => handleEditEvent(index)}
                    >
                      <S.TBodyCell scope="row">
                        <EventImage event={event} />
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">
                        <S.EventTitle>
                          <span className="title">{event.title}</span>
                          <span className="details">{`${event.city}, ${event.state} ${event.zipCode}`}</span>
                        </S.EventTitle>
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">
                        {event.categories[0]}
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">{`$${event.price}`}</S.TBodyCell>
                      <S.TBodyCell scope="row">
                        {event.activationDate
                          ? format(new Date(event.activationDate), 'MM/dd/yyyy')
                          : 'N/D'}
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">
                        {event.expirationDate
                          ? format(new Date(event.expirationDate), 'MM/dd/yyyy')
                          : 'N/D'}
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">{event.tags}</S.TBodyCell>
                      <S.TBodyCell scope="row">
                        <S.TagsContainer>
                          {!event.expirationDate ||
                          new Date(event?.expirationDate) > new Date() ? (
                            <S.StatusTag data-status={event.status}>
                              {EventHelper.getStatus(event.status)}
                            </S.StatusTag>
                          ) : null}
                          {event.expirationDate &&
                          new Date(event?.expirationDate) < new Date() ? (
                            <S.StatusTag data-status={5}>Expired</S.StatusTag>
                          ) : null}
                        </S.TagsContainer>
                      </S.TBodyCell>
                      <S.TBodyCell
                        scope="row"
                        title="Inline Edit"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleInlineEditClick(event)
                        }}
                      >
                        <Icon icon="Pencil2" size={24} />
                      </S.TBodyCell>
                    </S.TbodyRow>
                  )
                )
              )}
            </TableBody>
          }
        </S.Table>
      </S.TableContainer>
      <Dialog
        open={isAlertDialogOpen}
        onClose={() => setIsAlertDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {savingError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAlertDialogOpen(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </S.Container>
  )
}

interface EventImageProps {
  event: Event
}

function EventImage({ event }: EventImageProps) {
  const image = EventHelper.getImageFile(
    EventHelper.getEventTypeDescription(event.type)
  )
  return (
    <S.EventImage
      src={image}
      alt={event.title}
      style={{ maxWidth: '136px', maxHeight: '72px' }}
    />
  )
}

export default SectionEventsList
