"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamMemberSchema = exports.intersectAssociatedMasterDealers = exports.patchAssociatedMasterDealers = exports.patchAssociatedMasterDealer = exports.addDialogDirectUserAsync = exports.createDealerAsync = exports.getChanges = exports.getCoop = exports.hasCoop = exports.getAllowance = exports.hasAllowance = exports.getMasterDealerCoop = exports.hasMasterDealerCoop = exports.isPrincipal = exports.getMasterDealerPrincipals = exports.isCoopAllowanceEqual = exports.deleteCoopAlloance = exports.getCoopAlloance = exports.getCoopAlloanceIndex = exports.getCoopAllowanceText = exports.getCoopAllowancesText = exports.coopAllowanceCapabilityExists = exports.coopRecurringAllowanceExists = exports.coopAllowanceExists = exports.coopAllowancesExists = exports.getCoopAllowanceCapabilityOptions = exports.notUseMerchCenter = exports.masterDealerIdDirectvNDDealersOption = exports.masterDealerIdDirectvISPDealersOption = exports.masterDealerIdDirectvEmployeesOption = exports.masterDealerIdDirectvNDDealers = exports.masterDealerIdDirectvISPDealers = exports.masterDealerIdDirectvEmployees = exports.coopAllowanceCapabilityTypeDescriptions = exports.dealerGroups = exports.getDealerOptions = exports.dealerTypes = void 0;
var tslib_1 = require("tslib");
var lodash_1 = require("lodash");
var Yup = (0, tslib_1.__importStar)(require("yup"));
var enums_1 = require("../enums");
var utils_1 = require("../utils");
var channel_1 = require("./channel");
var http_1 = require("../http");
exports.dealerTypes = [
    // { value: 'authorized_dealer', label: 'Authorized Dealer' },
    { value: 'preferred_dealer', label: 'Preferred' },
    { value: 'top_event_dealer', label: 'Top Event' },
    { value: 'heb_dealer', label: 'HEB' },
];
var getDealerOptions = function () { return [
    // { id: 'authorized_dealer', value: 'Authorized Dealer' },
    { id: 'preferred_dealer', value: 'Preferred' },
    { id: 'top_event_dealer', value: 'Top Event' },
    { id: 'heb_dealer', value: 'HEB' },
]; };
exports.getDealerOptions = getDealerOptions;
exports.dealerGroups = [
    { value: '/dealer', label: 'Member' },
    { value: '/dealerAdmin', label: 'Admin' },
];
exports.coopAllowanceCapabilityTypeDescriptions = [
    {
        id: enums_1.CoopAllowanceCapabilityType.SINGLE_ALLOCATION,
        description: 'Single allocation',
    },
    {
        id: enums_1.CoopAllowanceCapabilityType.MONTHLY_RECURRING,
        description: 'Monthly recurring',
    },
];
exports.masterDealerIdDirectvEmployees = '9999999999';
exports.masterDealerIdDirectvISPDealers = '7777777777';
exports.masterDealerIdDirectvNDDealers = '8888888888';
exports.masterDealerIdDirectvEmployeesOption = {
    value: exports.masterDealerIdDirectvEmployees,
    label: 'DIRECTV employee (9999999999)',
};
exports.masterDealerIdDirectvISPDealersOption = {
    value: exports.masterDealerIdDirectvISPDealers,
    label: 'ISP dealer (7777777777)',
};
exports.masterDealerIdDirectvNDDealersOption = {
    value: exports.masterDealerIdDirectvNDDealers,
    label: 'ND dealer (8888888888)',
};
exports.notUseMerchCenter = [exports.masterDealerIdDirectvNDDealers];
var getCoopAllowanceCapabilityOptions = function () {
    return exports.coopAllowanceCapabilityTypeDescriptions.map(function (item) { return ({
        value: item.id.toString(),
        label: item.description,
    }); });
};
exports.getCoopAllowanceCapabilityOptions = getCoopAllowanceCapabilityOptions;
var coopAllowancesExists = function (coopAllowances) {
    return Array.isArray(coopAllowances) && coopAllowances.some(exports.coopAllowanceExists);
};
exports.coopAllowancesExists = coopAllowancesExists;
var coopAllowanceExists = function (coopAllowance) {
    return ((0, utils_1.isNumber)(coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.allowance) &&
        (coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.allowance) >= 0);
};
exports.coopAllowanceExists = coopAllowanceExists;
var coopRecurringAllowanceExists = function (coopAllowance) {
    return ((0, utils_1.isNumber)(coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.recurringAllowance) &&
        (coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.recurringAllowance) >= 0);
};
exports.coopRecurringAllowanceExists = coopRecurringAllowanceExists;
var coopAllowanceCapabilityExists = function (coopAllowance) {
    return ((0, utils_1.isNumber)(coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.capability) &&
        (coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.capability) > 0);
};
exports.coopAllowanceCapabilityExists = coopAllowanceCapabilityExists;
var getCoopAllowancesText = function (coopAllowances, outerSeparator, innerSeparator, withBrackets) {
    if (outerSeparator === void 0) { outerSeparator = ' '; }
    if (innerSeparator === void 0) { innerSeparator = ' '; }
    if (withBrackets === void 0) { withBrackets = false; }
    return Array.isArray(coopAllowances)
        ? coopAllowances
            .map(function (item) { return (0, exports.getCoopAllowanceText)(item, innerSeparator, withBrackets); })
            .join(outerSeparator)
        : '';
};
exports.getCoopAllowancesText = getCoopAllowancesText;
var getCoopAllowanceText = function (coopAllowance, separator, withBrackets) {
    var _a;
    if (separator === void 0) { separator = ' '; }
    if (withBrackets === void 0) { withBrackets = false; }
    return "".concat(coopAllowance.masterDealerId).concat((0, exports.coopAllowanceExists)(coopAllowance) ||
        (0, exports.coopAllowanceCapabilityExists)(coopAllowance)
        ? separator
        : '').concat((0, exports.coopAllowanceExists)(coopAllowance)
        ? utils_1.formatterCurrencyUSD.format(coopAllowance.allowance)
        : '').concat((0, exports.coopAllowanceExists)(coopAllowance) &&
        (0, exports.coopAllowanceCapabilityExists)(coopAllowance)
        ? separator
        : '').concat((0, exports.coopAllowanceCapabilityExists)(coopAllowance)
        ? "".concat(withBrackets ? '(' : '').concat((_a = exports.coopAllowanceCapabilityTypeDescriptions.find(function (item) { return item.id === coopAllowance.capability; })) === null || _a === void 0 ? void 0 : _a.description).concat((0, exports.coopRecurringAllowanceExists)(coopAllowance)
            ? "".concat(separator).concat(utils_1.formatterCurrencyUSD.format(coopAllowance.recurringAllowance))
            : '').concat(withBrackets ? ')' : '')
        : '');
};
exports.getCoopAllowanceText = getCoopAllowanceText;
var getCoopAlloanceIndex = function (coopAllowances, masterDealerId, channel) {
    if (!Array.isArray(coopAllowances) ||
        !(0, utils_1.isString)(masterDealerId) ||
        !masterDealerId.trim()) {
        return -1;
    }
    if (!!masterDealerId && (0, utils_1.isString)(channel) && !!channel) {
        masterDealerId = (0, channel_1.getMasterDealerId)(masterDealerId.trim().toUpperCase(), channel.trim().toUpperCase());
    }
    return coopAllowances.findIndex(function (item) {
        return item.masterDealerId.trim().toUpperCase() === masterDealerId.toUpperCase();
    });
};
exports.getCoopAlloanceIndex = getCoopAlloanceIndex;
var getCoopAlloance = function (coopAllowances, masterDealerId, channel) {
    var idx = (0, exports.getCoopAlloanceIndex)(coopAllowances, masterDealerId, channel);
    return idx !== -1 ? coopAllowances[idx] : undefined;
};
exports.getCoopAlloance = getCoopAlloance;
var deleteCoopAlloance = function (coopAllowances, coopAllowance, channel) {
    var idx = (0, utils_1.isString)(coopAllowance)
        ? (0, exports.getCoopAlloanceIndex)(coopAllowances, coopAllowance, channel)
        : (0, utils_1.isObject)(coopAllowance)
            ? (0, exports.getCoopAlloanceIndex)(coopAllowances, coopAllowance.masterDealerId)
            : -1;
    if (idx !== -1) {
        coopAllowances.splice(idx, 1);
    }
    return Array.isArray(coopAllowances) && coopAllowances.length
        ? coopAllowances
        : undefined;
};
exports.deleteCoopAlloance = deleteCoopAlloance;
var isCoopAllowanceEqual = function (left, right) {
    return (0, utils_1.isObject)(left) &&
        (0, utils_1.isObject)(right) &&
        (left === right ||
            (left.masterDealerId === right.masterDealerId &&
                left.allowance === right.allowance &&
                left.capability === right.capability &&
                left.recurringAllowance === right.recurringAllowance));
};
exports.isCoopAllowanceEqual = isCoopAllowanceEqual;
var getMasterDealerPrincipals = function (associatedMasterDealers, masterDealerId) {
    var _a, _b, _c, _d;
    return Array.isArray(associatedMasterDealers)
        ? (0, utils_1.isNonEmptyString)(masterDealerId)
            ? ((_d = (_c = (_b = (_a = associatedMasterDealers
                .find(function (item) { return (0, utils_1.isStringEqualCI)(item.masterDealerId, masterDealerId); })) === null || _a === void 0 ? void 0 : _a.masterDealer) === null || _b === void 0 ? void 0 : _b.find(function (item) {
                return (0, utils_1.isStringEqualCI)(item.masterDealerId, masterDealerId);
            })) === null || _c === void 0 ? void 0 : _c.principals) === null || _d === void 0 ? void 0 : _d.filter(function (item) { return (0, utils_1.isNonEmptyString)(item); })) || []
            : Array.from(new Set(associatedMasterDealers.reduce(function (principals, associatedMasterDealer) {
                return Array.isArray(associatedMasterDealer === null || associatedMasterDealer === void 0 ? void 0 : associatedMasterDealer.masterDealer)
                    ? (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], principals, true), associatedMasterDealer.masterDealer.reduce(function (_principals_, masterDealer) {
                        return Array.isArray(masterDealer === null || masterDealer === void 0 ? void 0 : masterDealer.principals)
                            ? (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], _principals_, true), masterDealer.principals
                                .filter(function (item) { return (0, utils_1.isString)(item) && !!item.trim(); })
                                .map(function (item) { return item.trim().toLowerCase(); }), true) : _principals_;
                    }, []), true) : principals;
            }, [])))
        : [];
};
exports.getMasterDealerPrincipals = getMasterDealerPrincipals;
var isPrincipal = function (dealer, principalEmails, masterDealerId) {
    var _a, _b, _c;
    return (((0, utils_1.isNonEmptyString)(dealer === null || dealer === void 0 ? void 0 : dealer.email) &&
        (0, exports.getMasterDealerPrincipals)((_a = dealer === null || dealer === void 0 ? void 0 : dealer.dealer) === null || _a === void 0 ? void 0 : _a.associatedMasterDealers, masterDealerId).indexOf((_c = (_b = dealer === null || dealer === void 0 ? void 0 : dealer.email) === null || _b === void 0 ? void 0 : _b.trim()) === null || _c === void 0 ? void 0 : _c.toLowerCase()) !== -1) ||
        (Array.isArray(principalEmails) &&
            principalEmails.findIndex(function (item) {
                return (0, utils_1.isStringEqualCI)(item, dealer === null || dealer === void 0 ? void 0 : dealer.email);
            }) !== -1));
};
exports.isPrincipal = isPrincipal;
var hasMasterDealerCoop = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, availableBalance) {
    return (0, utils_1.isObject)(coop)
        ? (coop === null || coop === void 0 ? void 0 : coop.hasCoop) &&
            (0, utils_1.isNumber)(coop === null || coop === void 0 ? void 0 : coop.availableBalance) &&
            (coop === null || coop === void 0 ? void 0 : coop.availableBalance) > 0
        : coop && (0, utils_1.isNumber)(availableBalance) && availableBalance > 0;
};
exports.hasMasterDealerCoop = hasMasterDealerCoop;
var getMasterDealerCoop = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, availableBalance) {
    return (0, utils_1.isObject)(coop) && (coop === null || coop === void 0 ? void 0 : coop.hasCoop) && (0, utils_1.isNumber)(coop === null || coop === void 0 ? void 0 : coop.availableBalance)
        ? coop === null || coop === void 0 ? void 0 : coop.availableBalance
        : coop && (0, utils_1.isNumber)(availableBalance)
            ? availableBalance
            : 0.0;
};
exports.getMasterDealerCoop = getMasterDealerCoop;
var hasAllowance = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, allowance) {
    return (0, utils_1.isObject)(coop)
        ? (coop === null || coop === void 0 ? void 0 : coop.allowanceUsed) && (0, utils_1.isNumber)(coop === null || coop === void 0 ? void 0 : coop.allowance) && (coop === null || coop === void 0 ? void 0 : coop.allowance) > 0
        : coop && (0, utils_1.isNumber)(allowance) && allowance > 0;
};
exports.hasAllowance = hasAllowance;
var getAllowance = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, allowance) {
    return (0, utils_1.isObject)(coop) && (coop === null || coop === void 0 ? void 0 : coop.allowanceUsed) && (0, utils_1.isNumber)(coop === null || coop === void 0 ? void 0 : coop.allowance)
        ? coop === null || coop === void 0 ? void 0 : coop.allowance
        : coop && (0, utils_1.isNumber)(allowance)
            ? allowance
            : 0.0;
};
exports.getAllowance = getAllowance;
var hasCoop = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, availableBalance, allowanceUsed, allowance) {
    return (0, utils_1.isObject)(coop)
        ? (0, exports.hasAllowance)(coop) || (0, exports.hasMasterDealerCoop)(coop)
        : (0, exports.hasAllowance)(allowanceUsed, allowance) ||
            (0, exports.hasMasterDealerCoop)(coop, availableBalance);
};
exports.hasCoop = hasCoop;
var getCoop = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, availableBalance, allowanceUsed, allowance) {
    return (0, utils_1.isObject)(coop)
        ? (0, exports.hasAllowance)(coop)
            ? (0, exports.getAllowance)(coop)
            : (0, exports.getMasterDealerCoop)(coop)
        : (0, exports.hasAllowance)(allowanceUsed, allowance)
            ? (0, exports.getAllowance)(allowanceUsed, allowance)
            : (0, exports.getMasterDealerCoop)(coop, availableBalance);
};
exports.getCoop = getCoop;
var getChanges = function (previousData, currentData, userId, changes) {
    var _a, _b, _c, _d;
    var currentChanges = {
        userId: userId || ((_a = currentData) === null || _a === void 0 ? void 0 : _a.userId),
        changes: changes || [],
    };
    (0, utils_1.checkStringValueChanges)(previousData === null || previousData === void 0 ? void 0 : previousData.dealer, currentData, enums_1.UserActivityLogKey.UNIQUE_DEALER_ID, currentChanges.changes);
    (0, utils_1.checkStringValueChanges)(previousData === null || previousData === void 0 ? void 0 : previousData.dealer, currentData, enums_1.UserActivityLogKey.BUSINESS_NAME, currentChanges.changes);
    (0, utils_1.checkBooleanValueChanges)(previousData, currentData, enums_1.UserActivityLogKey.ENABLED, currentChanges.changes);
    (0, utils_1.checkArrayValueChanges)(previousData === null || previousData === void 0 ? void 0 : previousData.groups, currentData.groups, enums_1.UserActivityLogKey.GROUPS, currentChanges.changes, utils_1.isStringEqualCI);
    (0, utils_1.checkArrayValueChanges)(previousData === null || previousData === void 0 ? void 0 : previousData.dealerTypes, currentData.dealerTypes, enums_1.UserActivityLogKey.DEALER_TYPES, currentChanges.changes, utils_1.isStringEqualCI);
    (0, utils_1.checkArrayValueChanges)((0, channel_1.getMasterDealerIds)((_b = previousData === null || previousData === void 0 ? void 0 : previousData.dealer) === null || _b === void 0 ? void 0 : _b.associatedMasterDealers), Array.isArray(currentData.associatedMasterDealers)
        ? currentData.associatedMasterDealers.map(function (item) {
            return (0, utils_1.isObject)(item)
                ? item.masterDealerId
                : item;
        })
        : [currentData.masterDealerId], enums_1.UserActivityLogKey.ASSOCIATED_MASTER_DEALERS, currentChanges.changes, utils_1.isStringEqualCI);
    (0, utils_1.checkArrayValueChanges)((0, channel_1.getAssociatedMasterDealerChannels)((_c = previousData === null || previousData === void 0 ? void 0 : previousData.dealer) === null || _c === void 0 ? void 0 : _c.associatedMasterDealers), currentData.channels, enums_1.UserActivityLogKey.CHANNELS, currentChanges.changes, utils_1.isStringEqualCI);
    (0, utils_1.checkBooleanValueChanges)(previousData === null || previousData === void 0 ? void 0 : previousData.dealer, currentData, enums_1.UserActivityLogKey.HAS_COOP, currentChanges.changes);
    (0, utils_1.checkArrayValueChanges)((_d = previousData === null || previousData === void 0 ? void 0 : previousData.dealer) === null || _d === void 0 ? void 0 : _d.coopAllowances, currentData.coopAllowances, enums_1.UserActivityLogKey.COOP_ALLOWANCES, currentChanges.changes, exports.isCoopAllowanceEqual, function (item) { return "{".concat((0, exports.getCoopAllowanceText)(item), "}"); });
    return currentChanges;
};
exports.getChanges = getChanges;
var createDealerAsync = function (data) { return (0, tslib_1.__awaiter)(void 0, void 0, void 0, function () {
    var dealer, createDealerResponse, userExistsResponse, addDialogDirectUserResponse, error_1;
    var _a, _b, _c, _d;
    return (0, tslib_1.__generator)(this, function (_e) {
        switch (_e.label) {
            case 0:
                dealer = {
                    user: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: (_b = (_a = data.email) === null || _a === void 0 ? void 0 : _a.trim()) === null || _b === void 0 ? void 0 : _b.toLowerCase(),
                        groups: data.groups,
                        enabled: true,
                        dealerTypes: data.dealerTypes,
                    },
                    dealer: {
                        hasCoop: data.hasCoop,
                        associatedMasterDealers: [
                            {
                                masterDealerId: data.masterDealerId,
                                channels: data.channels,
                            },
                        ],
                        coopAllowances: data.coopAllowances,
                        uniqueDealerId: (_c = data.uniqueDealerId) === null || _c === void 0 ? void 0 : _c.trim(),
                        businessName: (_d = data.businessName) === null || _d === void 0 ? void 0 : _d.trim(),
                    },
                };
                createDealerResponse = undefined;
                _e.label = 1;
            case 1:
                _e.trys.push([1, 6, , 7]);
                return [4 /*yield*/, http_1.httpUserInfo.get({
                        url: "/dealer/".concat(data.email, "/exists"),
                    })];
            case 2:
                userExistsResponse = _e.sent();
                if (userExistsResponse.data === true) {
                    return [2 /*return*/, 'That user already exists for another master dealer. Please use a different email address.'];
                }
                return [4 /*yield*/, http_1.httpUserInfo.post({
                        url: '/dealer',
                        data: dealer,
                    })];
            case 3:
                createDealerResponse = _e.sent();
                if (createDealerResponse.status !== 201) {
                    return [2 /*return*/, 'Error creating dealer'];
                }
                if (!(exports.notUseMerchCenter.findIndex(function (item) {
                    return (0, utils_1.isStringEqualCI)(item, data.masterDealerId);
                }) === -1)) return [3 /*break*/, 5];
                return [4 /*yield*/, (0, exports.addDialogDirectUserAsync)(data)];
            case 4:
                addDialogDirectUserResponse = _e.sent();
                if (addDialogDirectUserResponse.status !== 200) {
                    return [2 /*return*/, 'Error adding Dialog Direct user'];
                }
                _e.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                error_1 = _e.sent();
                return [2 /*return*/, (0, utils_1.formatAxiosError)(error_1)];
            case 7: return [2 /*return*/, createDealerResponse];
        }
    });
}); };
exports.createDealerAsync = createDealerAsync;
var addDialogDirectUserAsync = function (data) { return (0, tslib_1.__awaiter)(void 0, void 0, void 0, function () {
    var addUserDto;
    var _a, _b, _c, _d;
    return (0, tslib_1.__generator)(this, function (_e) {
        addUserDto = {
            companyName: '',
            emailAddress: (_b = (_a = data.email) === null || _a === void 0 ? void 0 : _a.trim()) === null || _b === void 0 ? void 0 : _b.toLowerCase(),
            channel: (_c = data.channels) === null || _c === void 0 ? void 0 : _c.join('|').toUpperCase(),
            corpId: (0, channel_1.getMasterDealerId)(data.masterDealerId, Array.isArray(data.channels) && data.channels.length
                ? data.channels[0]
                : ''),
            multiCorpId: (_d = data.channels) === null || _d === void 0 ? void 0 : _d.map(function (c) {
                return (0, channel_1.getMasterDealerId)(data.masterDealerId, c.toUpperCase());
            }).join('|'),
            address1: 'address1',
            addressee: '',
            city: 'Palo Alto',
            zip: '94301',
            state: 'CA',
            phoneNumber: '',
            attention: '',
            firstName: data.firstName,
            lastName: data.lastName,
        };
        return [2 /*return*/, http_1.httpDialogDirect.post({ url: '/AddCustomer', data: addUserDto })];
    });
}); };
exports.addDialogDirectUserAsync = addDialogDirectUserAsync;
var patchAssociatedMasterDealer = function (associatedMasterDealer) {
    if ((0, utils_1.isObject)(associatedMasterDealer) &&
        associatedMasterDealer.hasOwnProperty('masterDealer')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete associatedMasterDealer.masterDealer;
    }
};
exports.patchAssociatedMasterDealer = patchAssociatedMasterDealer;
var patchAssociatedMasterDealers = function (associatedMasterDealers) {
    if (Array.isArray(associatedMasterDealers)) {
        for (var i = 0, l = associatedMasterDealers.length; i < l; ++i) {
            (0, exports.patchAssociatedMasterDealer)(associatedMasterDealers[i]);
        }
    }
    else {
        (0, exports.patchAssociatedMasterDealer)(associatedMasterDealers);
    }
};
exports.patchAssociatedMasterDealers = patchAssociatedMasterDealers;
var intersectAssociatedMasterDealers = function (left, right) {
    var result = [];
    if (!Array.isArray(right)) {
        if (Array.isArray(left)) {
            result = (0, lodash_1.cloneDeep)(left);
            (0, exports.patchAssociatedMasterDealers)(result);
        }
        return result;
    }
    if (!Array.isArray(left)) {
        if ((0, utils_1.isNonEmptyArray)(right)) {
            result = (0, lodash_1.cloneDeep)(right);
            (0, exports.patchAssociatedMasterDealers)(result);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            result = result
                .filter(function (item) {
                return (0, utils_1.isNonEmptyString)(item) ||
                    (0, utils_1.isObject)(item);
            })
                .map(function (item) {
                return (0, utils_1.isString)(item)
                    ? {
                        masterDealerId: item.trim().toUpperCase(),
                    }
                    : item;
            });
        }
        return result;
    }
    result = (0, lodash_1.cloneDeep)(left);
    (0, exports.patchAssociatedMasterDealers)(result);
    var toDelete = (0, utils_1.except)(left, right, function (left, right) {
        return (0, utils_1.isString)(right)
            ? (0, utils_1.isStringEqualCI)(left.masterDealerId, right)
            : (0, utils_1.isStringEqualCI)(left.masterDealerId, right.masterDealerId);
    });
    var _loop_1 = function (i) {
        var idx = result.findIndex(function (item) {
            return (0, utils_1.isStringEqualCI)(item.masterDealerId, toDelete[i].masterDealerId);
        });
        if (idx !== -1) {
            result.splice(idx, 1);
        }
    };
    for (var i = toDelete.length - 1; i >= 0; --i) {
        _loop_1(i);
    }
    var toAdd = (0, utils_1.except)(right, left, function (left, right) {
        return (0, utils_1.isString)(left)
            ? (0, utils_1.isStringEqualCI)(left, right.masterDealerId)
            : (0, utils_1.isStringEqualCI)(left.masterDealerId, right.masterDealerId);
    });
    return (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], result, true), ((0, utils_1.isNonEmptyArray)(toAdd)
        ? toAdd.map(function (item) { return ({ masterDealerId: item }); })
        : []), true);
};
exports.intersectAssociatedMasterDealers = intersectAssociatedMasterDealers;
exports.TeamMemberSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter with first name.'),
    lastName: Yup.string().required('Please enter with last name.'),
    email: Yup.string().required('Please enter with email address.'),
    uniqueDealerId: Yup.string()
        .optional()
        .test('checkDupUniqueDealerId', 'A dealer with this "Unique dealer ID" already exists', function (value, context) {
        return (0, utils_1.isNonEmptyString)(value)
            ? new Promise(function (resolve, _) {
                http_1.httpUserInfo
                    .get({
                    url: "/dealer/byuniquedealerid/".concat(value),
                })
                    .then(function (response) {
                    if (Array.isArray(response.data) &&
                        response.data.filter(function (item) {
                            return item._id != context.parent.dealerId &&
                                item.uniqueDealerId == value;
                        }).length) {
                        resolve(false);
                    }
                    resolve(true);
                })
                    .catch(function () {
                    resolve(false);
                });
            })
            : Promise.resolve(true);
    }),
});
